import { useEffect, useState } from 'react'

if (typeof window !== 'undefined') {
  window.consumer = null
  window.subs = []
}

export default function useSocket(cacheKey, channelDetails, callbacks) {
  const [consumerAvailable, setConsumerAvailable] = useState(false)

  useEffect(() => {
    async function importConsumer() {
      if (window.consumer != null) {
        if (!consumerAvailable) {
          setConsumerAvailable(true)
        }
        return
      }

      window.consumer = (await import('./cableConsumer')).default()
      setConsumerAvailable(true)
    }

    importConsumer()
  }, [channelDetails?.channel, channelDetails?.id])

  useEffect(() => {
    if (cacheKey == null) {
      return
    }

    if (window.consumer?.subscriptions == null || !consumerAvailable) {
      return
    }

    if (channelDetails == null) {
      return
    }

    if (channelDetails.id == null) {
      return
    }

    if (channelDetails.channel == null) {
      return
    }

    const channelIdentifier = {
      channel: channelDetails.channel,
      id: channelDetails.id,
    }

    // If we're already subscribed to the target channel, don't retry
    const existingSub = findSubscription(
      channelIdentifier.channel,
      channelIdentifier.id
    )
    if (existingSub != null) {
      addListeners(
        channelIdentifier.channel,
        channelIdentifier.id,
        callbacks,
        cacheKey
      )
      return
    }

    const subscription = window.consumer.subscriptions.create(
      channelIdentifier,
      {
        connected: (...args) => {
          forListeners(
            channelDetails.channel,
            channelDetails.id,
            (listeners) => {
              listeners?.connected?.(...args)
            }
          )
        },
        disconnected: (...args) => {
          forListeners(channelDetails.channel, channelDetails.id, (listeners) =>
            listeners?.disconnected?.(...args)
          )
        },
        received: (data) => {
          forListeners(channelDetails.channel, channelDetails.id, (listeners) =>
            listeners?.received?.(data)
          )
        },
      }
    )
    addSubscription(
      channelIdentifier.channel,
      channelIdentifier.id,
      subscription,
      callbacks,
      cacheKey
    )

    return () => {
      unsubscribe(channelIdentifier.channel, channelIdentifier.id)
    }
  }, [cacheKey, channelDetails?.channel, channelDetails?.id, consumerAvailable])
}

function findSubscription(channel, id) {
  const result = window.subs.find(
    (sub) => sub.id === id && sub.channel === channel
  )
  return result?.subscription
}

function addListeners(channel, id, callbacks, cacheKey) {
  window.subs = window.subs.map((sub) => {
    if (sub.channel === channel && sub.id === id) {
      return {
        ...sub,
        listeners: {
          ...sub.listeners,
          [cacheKey]: callbacks,
        },
      }
    }

    return sub
  })
}

function forListeners(channel, id, cb) {
  const sub = window.subs.find(
    (sub) => sub.id === id && sub.channel === channel
  )
  if (sub?.listeners != null) {
    Object.values(sub.listeners)?.forEach(cb)
  }
}

function addSubscription(
  channel,
  id,
  subscription,
  callbacks,
  cacheKe,
  cacheKey
) {
  const existingSub = findSubscription(channel, id)

  // If we already subscribe to this channel, add the new listeners
  if (existingSub != null) {
    addListeners(channel, id, callbacks)
    return existingSub
  }

  const sub = {
    channel,
    id,
    subscription,
    listeners: {
      [cacheKey]: callbacks,
    },
  }
  window.subs.push(sub)
  return sub
}

function unsubscribe(channel, id) {
  const subscription = findSubscription(channel, id)
  if (subscription == null) {
    return
  }

  window.consumer.subscriptions.remove(subscription)
  window.subs = window.subs.filter(
    (sub) => sub.id !== id && sub.channel !== channel
  )
}
