import React from 'react'

const StepCount = ({ steps, activeStep = 0 }) => (
  <div className="w-full flex flex-row justify-around -mx-1">
    {steps.map((step) => {
      let borderColor = 'alt-bg'
      if (activeStep > step?.stepNum) {
        borderColor = 'primary'
      } else if (activeStep === step?.stepNum) {
        borderColor = 'subtitle'
      }

      return (
        <div
          key={step?.stepNum}
          className={`border-b-4 rounded-md border-${borderColor} mx-2 w-full`}
        />
      )
    })}
  </div>
)

export default StepCount
