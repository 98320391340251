import { omit } from 'lodash'

/* eslint-disable camelcase */
function getMixpanelData(data) {
  if (data == null) {
    return null
  }

  const {
    type,
    item,
    user,
    stamp,
    group,
    cluster,
    topic,
    from,
    to,
    message,
    resource,
    joined_at,
    followed_at,
    url,
  } = data

  const mixpanelData = {}

  if (user) {
    mixpanelData.user = {
      allow_marketing: user.allow_marketing,
      avatar: !!user.avatar,
      bio: !!user.bio,
      browser_notification_preferences: user.browser_notification_preferences,
      cover_image: !!user.cover_image,
      daily_digest_enabled: user.daily_digest_enabled,
      email_notification_preferences: user.email_notification_preferences,
      id: user.id,
      slug: user.slug,
      link: !!user.link,
      location: !!user.location,
      onboarded_at: user.onboarded_at,
      tutorial_step: user.tutorial_step || 0,
      weekly_digest_enabled: user.weekly_digest_enabled,
      managed_groups: user.managed_groups || [],
      group_memberships: user.group_memberships || [],
      clusters:
        user.badges?.map((originalObject) => omit(originalObject, 'logo')) ||
        [],
      followed_clusters:
        user.followed_badges?.map((originalObject) =>
          omit(originalObject, 'logo')
        ) || [],
      managed_clusters:
        user.managed_badges?.map((originalObject) =>
          omit(originalObject, 'logo')
        ) || [],
      stamps:
        user.stamps?.map((originalObject) => omit(originalObject, 'logo')) ||
        [],
    }
  }

  if (stamp) {
    mixpanelData.stamp = {
      creator: omit(stamp.creator, 'avatar'),
      recipient: stamp.recipient,
      stamp: omit(stamp, 'logo'),
      id: stamp.id,
      stampable_id: stamp.stampable_id,
      stampable_type: stamp.stampable_type,
    }
  }

  if (group) {
    mixpanelData.group = {
      ...group,
      avatar: !!group.avatar,
      cover_image: !!group.cover_image,
      description: !!group.description,
      link: !!group.link,
      location: !!group.location,
      clusters:
        group.badges?.map((originalObject) => omit(originalObject, 'logo')) ||
        [],
    }
  }

  if (cluster) {
    mixpanelData.cluster = {
      description: !!cluster.description,
      follower_count: cluster.follower_count,
      group_count: cluster.group_count,
      id: cluster.id,
      link: !!cluster.link,
      location: !!cluster.location,
      logo: !!cluster.logo,
      marketing_logo: !!cluster.marketing_logo,
      name: cluster.name,
      recommended_group_ids: cluster.recommended_group_ids,
      showcases: !!cluster.showcases,
      slug: cluster.slug,
      welcome_background_color: !!cluster.welcome_background_color,
      welcome_body: !!cluster.welcome_body,
      welcome_text_color: !!cluster.welcome_text_color,
      welcome_title: !!cluster.welcome_title,
      welcome_video_url: !!cluster.welcome_video_url,
    }
  }

  if (from) {
    mixpanelData.from = {
      avatar: !!from.avatar,
      bio: !!from.bio,
      id: from.id,
      slug: from.slug,
    }
  }

  if (resource) {
    mixpanelData.resource = {
      alt_text: !!resource.alt_text,
      content_type: resource.content_type,
      created_at: resource.created_at,
      description: !!resource.description,
      group_name: resource.group_name,
      group_slug: resource.group_slug,
      has_image: !!resource?.image,
      has_file: !!resource?.file,
      has_link: !!resource?.link,
      id: resource.id,
      name: resource.name,
      resourceable_id: resource.resourceable_id,
      resourceable_type: resource.resourceable_type,
      slug: resource.slug,
      updated_at: resource.updated_at,
    }
  }

  if (topic) mixpanelData.topic = topic
  if (to) mixpanelData.to = to
  if (message) mixpanelData.message = message
  if (joined_at) mixpanelData.joined_at = joined_at
  if (followed_at) mixpanelData.followed_at = followed_at
  if (url) mixpanelData.url = url

  if (item && type) {
    if (type.toLowerCase() === 'post') {
      mixpanelData.post = {
        has_image: !!item?.image,
        has_file: !!item?.file,
        has_link: !!item?.link,
        slug: item?.slug,
        id: item?.id,
        reply_count: item?.reply_count,
        unfurl: item?.unfurl,
        isEdited: item?.isEdited,
        isPinned: item?.isPinned,
        stamps: item?.stamps,
        postable: {
          id: item?.postable?.id,
          slug: item?.postable?.slug,
          visibility: item?.postable?.visibility,
          clusters: item?.postable?.badges,
          avatar: !!item?.postable?.avatar,
        },
        user: {
          avatar: !!item?.user?.avatar,
          id: item?.user?.id,
          slug: item?.user?.slug,
        },
        user_has_upvoted: item?.user_has_upvoted,
        upvote_count: item?.upvote_count,
      }
    } else if (type.toLowerCase() === 'event') {
      mixpanelData.event = {
        has_image: !!item?.image,
        has_link: !!item?.link,
        has_location: !!item?.location,
        slug: item?.slug,
        id: item?.id,
        reply_count: item?.reply_count,
        stamps: item?.stamps,
        ends_at: item?.ends_at,
        starts_at: item?.starts_at,
        eventable: {
          id: item?.eventable?.id,
          slug: item?.eventable?.slug,
          visibility: item?.eventable?.visibility,
          clusters: item?.eventable?.badges,
          avatar: !!item?.eventable?.avatar,
        },
        owner: {
          avatar: !!item?.owner?.avatar,
          id: item?.owner?.id,
          slug: item?.owner?.slug,
        },
        user_has_upvoted: item?.user_has_upvoted,
        upvote_count: item?.upvote_count,
      }
    }
  }

  return mixpanelData
}

export { getMixpanelData }
