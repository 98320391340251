/*
 * Wraps each function call with the SWR mutator. Only useful for function which
 * return equivalent data to the initial useSWR fetcher
 *
 * Example usage:
 * const { updateGroup } = useMutators(mutateGroup, { updateGroup })
 *
 */
export default function useMutators(mutate, fnsByName) {
  return Object.keys(fnsByName).reduce((acc, fnName) => {
    const fn = fnsByName[fnName]

    acc[fnName] = async function (...args) {
      const result = await fn(...args)
      mutate(result)
      return result
    }

    return acc
  }, {})
}
