import Router from 'next/router'
import { mutate } from 'swr'
import { logout as logoutReq } from './useUser'

export default  function logout() {
  logoutReq()

  window.OneSignalDeferred = window.OneSignalDeferred || []
  // eslint-disable-next-line no-undef
  OneSignalDeferred.push(async function (OneSignal) {
    OneSignal.logout()
  })

  if (
    !Router.pathname.startsWith('/login') &&
    !Router.pathname.startsWith('/passwordreset') &&
    !Router.pathname.startsWith('/signup') &&
    !Router.pathname.contains('invite=') &&
    !Router.pathname.contains('bid=')
  ) {
    mutate(
      // eslint-disable-next-line no-unused-vars
      (key) => true,
      undefined,
      { revalidate: false }
    )

    Router.push('/signup')
  }
}
