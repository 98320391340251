import { useEffect } from 'react'

export default function useTrackPageActive(analytics) {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        analytics.trackData('user_unload', analytics.userData)
      }

      if (document.visibilityState === 'visible') {
        analytics.trackData('user_load', analytics.userData)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
}
