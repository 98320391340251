import classnames from 'classnames'
import Lottie from 'react-lottie-player'
import HomePageEmpty from '../../public/lottie/HomePageEmpty.json'
import Button from './Button'
import Icon from './Icon'

export default function EmptyView({
  title,
  accessory,
  instructions,
  small,
  defaultAccessory,
  showReload = false,
}) {
  const wrapper = classnames(
    'w-full md:w-1/2 mx-auto px-4 lg:px-0 flex flex-col items-center',
    {
      'my-16': !small,
    },
    {
      'my-4': small,
    }
  )

  return (
    <div className={wrapper}>
      <p className="text-gray-600 text-center text-sm">{title}</p>
      {(accessory || defaultAccessory) && (
        <div className="my-8 object-contain">
          {accessory}
          {defaultAccessory && (
            <Lottie
              loop
              animationData={HomePageEmpty}
              play
              style={{ width: 350, height: 350 }}
            />
          )}
        </div>
      )}
      <div className="flex flex-col item-start mx-2 md:mx-8">
        {instructions != null &&
          instructions.map((item, index) => (
            <div className="flex flex-row my-3" key={`info-${index}`}>
              <Icon name={item.icon} color="primary" size={42} />
              <div className="flex flex-col items-start ml-6 text-sm">
                <span>{item.title}</span>
                {item.link && (
                  <a
                    id={item.linkLabel?.replaceAll(' ', '_')}
                    href={item.link}
                    className="font-bold"
                  >
                    {item.linkLabel}
                  </a>
                )}
              </div>
            </div>
          ))}
      </div>
      {showReload && (
        <Button onClick={() => window.location.reload()}>Reload</Button>
      )}
    </div>
  )
}
