import classnames from 'classnames'

export default function Container({
  children,
  className,
  large,
  noBottomPadding,
}) {
  return (
    <div
      className={classnames(
        'w-full',
        {
          'max-w-xl md:max-w-3xl 2xl:max-w-5xl': !large,
          'max-w-2xl md:max-w-5xl 2xl:max-w-7xl': large,
        },
        {
          'pb-20': !noBottomPadding,
          'pb-0': noBottomPadding,
        },
        className
      )}
    >
      {children}
    </div>
  )
}
