import { useEffect, useState } from 'react'
import steps from '../../config/steps'
import useUser from '../../lib/useUser'
import { useAnalytics } from '../context/AnalyticsProvider'
import Complete from './Complete'
import Step from './Step'
import StepCount from './StepCount'

const Stepper = () => {
  const { user, updateUser } = useUser()
  const { track } = useAnalytics()
  const [activeStepNum, setActiveStepNum] = useState(
    user?.tutorial_step || null
  )
  const activeStep = steps.find((obj) => obj.stepNum === activeStepNum)

  useEffect(() => {
    setActiveStepNum(user?.tutorial_step)
  }, [user, user?.tutorial_step])

  const completeStep = async (skipped = false) => {
    const body = { ...user, tutorial_step: activeStepNum + 1 }

    try {
      await updateUser(body)

      if (activeStepNum + 1 < 6) {
        track(`onboard_step_${activeStepNum + 1}`, {
          slug: user?.slug,
          id: user?.id,
          allow_marketing: user?.allow_marketing,
          skipped,
          step: activeStep,
        })
      }
    } catch (error) {
      console.error('An unexpected error happened:', error)
    }
  }

  if (!user?.onboarded_at) {
    return null
  }

  if (activeStepNum !== null && activeStepNum <= steps?.length) {
    return (
      <div className="flex flex-col w-full max-w-350 h-44 max-h-44 px-4 py-4 bg-white rounded-lg shadow fixed bottom-36 md:bottom-20 right-2 md:right-5">
        <StepCount steps={steps} activeStep={activeStepNum} />
        {activeStepNum < steps?.length && (
          <Step activeStep={activeStep} completeStep={completeStep} />
        )}
        {activeStepNum >= steps?.length && <Complete hide={completeStep} />}
      </div>
    )
  }

  return null
}

export default Stepper
