const SeoConfig = {
  titleTemplate: '%s | Community Lab',
  defaultTitle: 'Community Lab | Where innovation happens',
  description:
    'Community Lab is an innovation and collaboration platform for positive impact. Through our digital platform, you can share ideas, discover challenges and contribute.',
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://communitylab.app/',
    site_name: 'Community Lab',
    description:
      'Community Lab is an innovation and collaboration platform for positive impact. Through our digital platform, you can share ideas, discover challenges and contribute.',
    images: [
      {
        url: 'https://app.communitylab.app/cl-meta.jpg',
      },
    ],
  },
  additionalMetaTags: [
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
  ],
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon/favicon-32x32.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      href: '/favicon/favicon-16x16.png',
      sizes: '16x16',
    },
    {
      rel: 'apple-touch-icon',
      href: '/favicon/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_2048.png',
      sizes: '2048x2732',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_1668.png',
      sizes: '1668x2224',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_1536.png',
      sizes: '1536x2048',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_1125.png',
      sizes: '1125x2436',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_1242.png',
      sizes: '1242x2208',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_1750.png',
      sizes: '1750x1334',
    },
    {
      rel: 'apple-touch-startup-image',
      href: '/favicon/apple_splash_640.png',
      sizes: '640x1136',
    },
    {
      rel: 'manifest',
      href: '/manifest.json',
    },
  ],
}

export default SeoConfig
