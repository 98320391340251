import { useRouter } from 'next/router'
import { useEffect } from 'react'
import * as gtag from './gtag'

export default function useTrackPageChange(analytics) {
  const router = useRouter()

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const handleRouteChange = (url) => {
        gtag.pageview(url)
        analytics.trackPageView(
          analytics.getData({ user: analytics.user, url })
        )
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])
}
