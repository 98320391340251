import IcomoonReact from 'icomoon-react'
import iconSet from '../../public/icons/selection.json'
import colors from '../../config/colors'

export default function Icon({ color, size = 32, name, className = '' }) {
  return (
    <IcomoonReact
      iconSet={iconSet}
      color={colors[color] || colors.black}
      size={size}
      icon={name}
      className={className}
    />
  )
}
