// Used in tailwind config as well as JS
module.exports = {
  primary: '#00EA95',
  black: '#111111',
  offBlack: '#575B61',
  disabled: '#757B83',
  white: '#FFFFFF',
  'alt-bg': '#F4F5F8',
  alert: '#C94C4C',
  badge: '#E7B812',
  'badge-20': '#FAF1D0',
  'badge-90': '#E8BF2A',
  subtitle: '#A9ACB1',
  'topic-blue': '#018DD9',
  'topic-blue-20': '#CCE8F7',
  'topic-yellow': '#E7B812',
  'topic-yellow-20': '#F2E9C6',
  'topic-red': '#F7682E',
  'topic-red-20': '#F0D2D6',
  'topic-purple': '#8A26E0',
  'topic-purple-20': '#E3CAF6',
  'topic-green': '#31D096',
  'topic-green-20': '#D6F6EA',
  'topic-pink': '#FD4E7A',
  'topic-pink-20': '#FDD2DF',
  notification: '#C94C4C',
  divider: '#EAEAEA',
  overlay: '#00000090',
  'overlay-20': '#00000020',
  'placeholder-blue': '#142A44',
  section: '#DCDCDF',
  'pinned-start': '#321243',
}
