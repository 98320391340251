import Image from 'next/image'
import { useRouter } from 'next/router'

const Step = ({ activeStep, completeStep }) => {
  const router = useRouter()

  const handleAction = (route) => {
    router.push(route)
    completeStep()
  }

  if (activeStep) {
    return (
      <div className="flex flex-row items-start mt-5">
        <Image
          key={`${activeStep?.title}-step-icon`}
          src={activeStep?.image}
          alt={`${activeStep?.title} icon`}
          width={96}
          height={96}
          className="object-cover w-24 h-24"
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <div className="flex flex-col ml-4">
          <p className="text-black font-bold text-left text-base">
            {activeStep?.title}
          </p>
          <p className="text-black text-left text-sm line-clamp-2">
            {activeStep?.text}
          </p>

          <div className="flex flex-row mt-2">
            <button
              onClick={() => {
                handleAction(activeStep?.action)
              }}
              className="bg-topic-green bg-opacity-10 rounded-2xl text-topic-green text-sm py-1 px-4"
            >
              {activeStep?.actionLabel}
            </button>
            <button
              onClick={() => completeStep(true)}
              className="text-disabled text-sm ml-4"
            >
              Skip this
            </button>
          </div>
        </div>
      </div>
    )
  }

  return null
}

export default Step
