import { createContext, useContext, useState } from 'react'

const AppContext = createContext()

export function ContextWrapper({ children }) {
  const [cacheKey, setCacheKey] = useState(null)

  return (
    <AppContext.Provider value={{ value: cacheKey, setCacheKey }}>
      {children}
    </AppContext.Provider>
  )
}

export function useCacheKey() {
  return useContext(AppContext)
}
