import React from 'react'
import TrophyIcon from '../../public/stepper/trophy.svg'

const Complete = ({ hide }) => (
  <div className="flex flex-col justify-between items-center mt-2">
    <TrophyIcon className="w-20 h-14" />
    <p className="text-black text-center text-sm w-64">
      Complete! You're now a master at using Community Lab!
    </p>
    <button onClick={hide} className="text-disabled text-sm mt-2">
      Close this
    </button>
  </div>
)

export default Complete
