import classnames from 'classnames'
import Link from 'next/link'
import { forwardRef } from 'react'
import { useAnalytics } from '../context/AnalyticsProvider'

const COLORS = {
  alert: {
    outline: 'text-alert border-alert',
    solid: 'bg-alert text-white',
  },
  primary: {
    outline: 'text-topic-green border-topic-green',
    solid: 'bg-topic-green text-white',
    alt: 'bg-white text-topic-green',
  },
  white: {
    outline: 'text-white border-white',
    solid: 'bg-white text-black',
  },
  default: {
    outline: 'text-alt-bg border-alt-bg',
    solid: 'bg-alt-bg text-black',
  },
}

function Button(
  {
    className,
    colorClass,
    children,
    onClick,
    outline,
    primary,
    alt,
    white,
    tiny,
    small,
    alert,
    bold,
    noPadding,
    smallRadius,
    href,
    dataCy,
    targetBlank,
    type = 'button',
    disabled,
    analyticsEvent,
    analyticsData,
  },
  ref
) {
  const { track } = useAnalytics()

  let color = 'default'
  if (primary) {
    color = 'primary'
  }
  if (alert) {
    color = 'alert'
  }
  if (white) {
    color = 'white'
  }

  let padding = 'px-8 py-2'
  if (noPadding) {
    padding = 'p-0'
  }

  let fontSize = 'text-base'
  if (tiny) {
    fontSize = 'text-tiny'
  }
  if (small) {
    fontSize = 'text-sm'
    padding = noPadding ? '' : 'px-8 py-2'
  }

  let variant = 'solid'
  if (outline) {
    variant = 'outline'
  }

  if (alt) {
    variant = 'alt'
  }

  const bgColor = colorClass != null ? colorClass : null
  const boldStyle = bold ? 'font-bold' : null
  const backgroundStyle = outline
    ? 'bg-opacity-0 rounded-3xl border-2 py-1 px-4'
    : null
  const colorStyle = COLORS[color][variant]
  const roundedStyle = smallRadius ? 'rounded' : 'rounded-full'
  const appliedStyle = classnames(
    'cursor-pointer text-center focus:outline-none',
    colorStyle,
    fontSize,
    bgColor,
    backgroundStyle,
    boldStyle,
    padding,
    roundedStyle,
    className,
    {
      'cursor-not-allowed opacity-50': disabled,
    }
  )

  if (href) {
    return (
      <Link
        href={href}
        data-cy={dataCy}
        ref={ref}
        target={targetBlank ? '_blank' : '_self'}
        className={appliedStyle}
        onClick={() =>
          track(
            analyticsEvent || 'url_clicked',
            analyticsData || {
              url: href,
            }
          )
        }
      >
        {children}
      </Link>
    )
  }
  return (
    <button
      ref={ref}
      className={appliedStyle}
      onClick={disabled ? null : onClick}
      data-cy={dataCy}
      type={type}
    >
      {children}
    </button>
  )
}

export default forwardRef(Button)
