import logout from './logout'

export default async function fetchJson(...args) {
  try {
    const response = await fetch(...args)

    let data
    if (
      response.headers.get('content-type')?.indexOf('application/json') > -1
    ) {
      // if the server replies
      // if there's a network error, it will throw at the previous line
      try {
        data = await response.json()

        if (response.headers.get('total-count')) {
          data._meta = { total_count: response.headers.get('total-count') }
        }
      } catch (err) {
        // handle no data returned from server
        return data
      }
    } else {
      data = await response.text()
    }

    if (response.ok) {
      return data
    }

    if (response.status === 401) {
      logout()
      return null
    }

    const error = new Error(
      response.statusText || `Unknown response error - ${response.status}`
    )
    error.response = response
    error.data = data
    throw error
  } catch (error) {
    // Handles response with no body
    if (error instanceof SyntaxError) {
      console.error(error)
      return
    }

    if (!error.data) {
      error.data = { message: error.message }
    }
    throw error
  }
}
