import * as Sentry from '@sentry/nextjs'
import mixpanel from 'mixpanel-browser'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getMixpanelData } from '../../lib/mixpanel/mixpanelDataHelpers'
import useTrackPageActive from '../../lib/useTrackPageActive'
import useTrackPageChange from '../../lib/useTrackPageChange'

const AnalyticsContext = createContext()

export default function AnalyticsProvider({ children }) {
  const [mixpanelInstance, setMixpanelInstance] = useState()
  const userRef = useRef(null)

  useEffect(() => {
    const name = process.env.NODE_ENV === 'development' ? 'dev' : 'prod'
    const instance = mixpanel.init(
      process.env.NEXT_PUBLIC_MIXPANEL_ID,
      {
        persistence: 'localStorage',
        debug: process.env.NODE_ENV === 'development',
      },
      name
    )
    setMixpanelInstance(instance)
  }, [])

  const updateUser = useCallback(
    (newUser) => {
      if (
        newUser?.id === userRef.current?.id &&
        newUser?.analytics_enabled === userRef.current?.analytics_enabled
      ) {
        return
      }

      userRef.current = newUser

      if (!newUser || userRef.current?.analytics_enabled === false) {
        Sentry.setUser(null)
        if (mixpanelInstance) {
          mixpanelInstance.reset()
        }
        return
      }

      Sentry.setUser({ email: newUser.email })

      if (mixpanelInstance) {
        const mixpanelData = getMixpanelData(newUser)
        mixpanelInstance.identify(newUser.slug)
        mixpanelInstance.people.set({
          ...mixpanelData,
        })
      }
    },
    [mixpanelInstance, userRef.current?.id]
  )

  const track = useCallback(
    (event, data = null) => {
      if (!mixpanelInstance || userRef.current?.analytics_enabled === false) {
        return
      }

      try {
        mixpanelInstance.track(event, data)
      } catch (error) {
        console.error('Mixpanel error tracking event:', event)
      }
    },
    [mixpanelInstance]
  )

  const trackData = useCallback(
    (event, data = {}) => {
      const mixpanelData = getMixpanelData(data)
      track(event, mixpanelData)
    },
    [mixpanelInstance]
  )

  const trackUrl = useCallback(
    (elementId) => {
      if (!mixpanelInstance) {
        return
      }

      try {
        mixpanelInstance.track_links(
          `#${elementId}`,
          'url_clicked',
          function (e) {
            return { url: e.href, text: e.innerText }
          }
        )
      } catch (error) {
        console.error('Mixpanel error for link click: ', error)
      }
    },
    [mixpanelInstance]
  )

  const trackPageView = useCallback(
    // mixpanel.track_pageview
    (data) => {
      if (!mixpanelInstance) {
        return
      }

      try {
        mixpanelInstance.track_pageview(data)
      } catch (error) {
        console.error('Mixpanel error tracking page view: ', error)
      }
    },
    [mixpanelInstance]
  )

  const analytics = {
    updateUser,
    track,
    trackData,
    trackUrl,
    trackPageView,
    user: userRef.current,
    get userData() {
      return getMixpanelData(userRef.current)
    },
    getData(data = null) {
      return getMixpanelData(data)
    },
  }

  useTrackPageChange(analytics)
  useTrackPageActive(analytics)

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export function useAnalytics() {
  return useContext(AnalyticsContext)
}
