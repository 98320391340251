/* eslint-disable no-param-reassign */
import FormData from 'form-data'
import { isDate } from 'lodash'

/*
 * Accepts data in the form of a js object and returns formData
 *
 * E.g.
 *   getFormData({user: {name: 'Chris', numbers: [31, 29, 17]}})
 *
 *   returns formData with these keys and values:
 *     user[name]
 *       Chris
 *
 *     user[numbers][]
 *       31
 *
 *     user[numbers][]
 *       29
 *
 *     user[numbers][]
 *       17
 */
export default function getFormData(data, formData, previousKey) {
  if (formData == null) {
    formData = new FormData()
  }

  if (!(data instanceof Object)) {
    return
  }

  for (let key of Object.keys(data)) {
    const value = data[key]

    if (previousKey != null) {
      key = `${previousKey}[${key}]`
    }

    if (isDate(value)) {
      formData.append(key, value.toISOString())
    } else if (isObject(value)) {
      // Normal object with nested keys to upload
      getFormData(value, formData, key)
    } else if (isFile(value)) {
      formData.append(`${key}`, value)
    } else if (Array.isArray(value)) {
      if (value.length === 0) {
        formData.append(key, null)
      }

      for (const element of value) {
        if (isDate(value)) {
          formData.append(`${key}[]`, value.toISOString())
        } else if (isObject(element)) {
          getFormData(element, formData, `${key}[]`)
        } else if (isFile(element)) {
          formData.append(`${key}[]`, element)
        } else {
          formData.append(`${key}[]`, element)
        }
      }
    } else if (value != null) {
      // We can only send files or strings as form data
      // so null would become the string "null"
      formData.append(key, value)
    }
  }

  return formData
}

function isObject(value) {
  return value instanceof Object && !Array.isArray(value) && !isFile(value)
}

function isFile(value) {
  return value instanceof File
}
