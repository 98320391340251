export default [
  {
    stepNum: 0,
    title: 'Join a group',
    text: `Start collaborating by joining a group. That's where the magic is!`,
    action: '/discover',
    actionLabel: 'Explore groups',
    image: '/stepper/group.svg',
  },
  {
    stepNum: 1,
    title: 'Complete your profile',
    text: `Add a photo, description and links to help people connect with you.`,
    action: '/settings/account',
    actionLabel: 'Edit Profile',
    image: '/stepper/profile.svg',
  },
  {
    stepNum: 2,
    title: 'Join a cluster',
    text: `Clusters on Community Lab gather great people and projects together.`,
    action: '/discover',
    actionLabel: 'Explore clusters',
    image: '/stepper/badge.svg',
  },
  {
    stepNum: 3,
    title: 'Review your notification settings',
    text: `Change how you get notified of new activity on Community Lab.`,
    action: '/settings',
    actionLabel: 'Go to Settings',
    image: '/stepper/notifications.svg',
  },
  {
    stepNum: 4,
    title: 'Post something',
    text: `Engage with your groups. Share a challenge, idea or opportunity.`,
    action: { pathname: '/', query: { post: 'new' } },
    actionLabel: 'Create a post',
    image: '/stepper/post.svg',
  },
]
